<template>
	<!-- 商品资料 -->
	<div class="commodityData">
		<div class="search">
			<div class="search-l">
				<div class="l-item">
					<div class="item-label">商品名称:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入商品名称搜索" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">分类:</div>
					<div class="item-input">
						<el-select v-model="searchForm.category_id" placeholder="请选择分类" style="width: 100%;">
							<el-option v-for="item in classifyList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择分类" style="width: 100%;">
							<el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="page = 1, loading = true, getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button @click.stop="handleExport">导出</el-button>
				<el-button type="warning" @click.stop="syncStock" :disabled="selectionList.length == 0">批量同步到可售</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="ID" prop="id" align="center" width="60"></el-table-column>
				<el-table-column label="分类" prop="category_name" align="center"></el-table-column>
				<el-table-column label="商品名称" prop="goods_name" align="center" width="150"></el-table-column>
				<el-table-column label="商品图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="商品卖点" prop="goods_intro" align="center" width="170"></el-table-column>
				<el-table-column label="自定义属性" prop="attribute" align="center"></el-table-column>
				<el-table-column label="预订价" prop="price" align="center" width="120">
				</el-table-column>
				<el-table-column label="最低折扣价" prop="min_price" align="center" width="120">
				</el-table-column>
				<el-table-column label="指导价" prop="guide_price" align="center" width="120">
				</el-table-column>

				<el-table-column label="平均成本价" prop="stock.avg_cost" align="center" width="120">
					<template slot-scope="scope">
						<span>{{ scope.row.stock_info.avg_cost }}</span>
					</template>
				</el-table-column>
				<el-table-column label="最后成本价" prop="stock.last_cost" align="center" width="120">
					<template slot-scope="scope">
						<span>{{ scope.row.stock_info.last_cost }}</span>
					</template>
				</el-table-column>
				<el-table-column label="实时库存" prop="stock.stock" align="center" width="100">
					<template slot-scope="scope">
						<span style="font-weight: 600;color:#409EFF">{{ scope.row.stock_info.stock }}</span>
					</template>
				</el-table-column>
				<el-table-column label="到货类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.arrived_type == 1">当日达</span>
						<span v-if="scope.row.arrived_type == 2">次日达</span>
						<span v-if="scope.row.arrived_type == 3">预售<br> ({{ scope.row.arrived_date }}到)</span>
					</template>
				</el-table-column>
				<el-table-column label="截单时间" align="center" width="80">
					<template slot-scope="scope">
						<span>{{ scope.row.cut_time ? scope.row.cut_time : "全局设置" }}</span>
					</template>
				</el-table-column>

				<el-table-column label="排序" prop="sort" sortable align="center">
				</el-table-column>
				<el-table-column label="操作" align="center" :width="wxList.length > 0 ? '260' : '160'" fixed="right">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" @click.stop="syncStock(scope.row)">同步到可售</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>

	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import CryptoJS from 'crypto-js'
	import {
		Row
	} from 'element-ui'
	var timestamp = new Date().getTime()
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				selectionList: [],
				arrivedDialog: false,
				arrivedForm: {
					arrived_type: 1,
					arrived_date: ""
				},
				searchForm: {},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					arrived_type: 1,
					goods_desc: ''
				},
				isLimitMin: 0, //最小起购
				isQuota: 0, //单次限购
				classifyList: [], //分类
				statusList: [{
						id: 0,
						name: '全部'
					},
					{
						id: 1,
						name: '正常'
					},
					{
						id: 2,
						name: '下架'
					},
					{
						id: 3,
						name: '违规'
					}
				],
				// 到货类型
				arrivedList: [{
						id: 1,
						name: '当日达'
					},
					{
						id: 2,
						name: '次日达'
					},
					{
						id: 3,
						name: '预售'
					}
				],
				//状态
				typeList: [{
						id: 0,
						name: '否'
					},
					{
						id: 1,
						name: '是'
					}
				],
				uploadHeaders: {
					token: window.sessionStorage.getItem('token'),
					sign: CryptoJS.MD5(sessionStorage.getItem('token') + 'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp)
						.toString(),
					timestamp: timestamp
				},
				editorOption: {
					placeholder: "请输入",
					theme: "snow",
					modules: {
						toolbar: {
							container: '#toolbar',
							handlers: {
								'image': function(value) {
									if (value) {
										// 触发input框选择图片文件                    
										document.querySelector('.avatar-uploader-editor input').click()
									} else {
										this.quill.format('image', false);
									}
								},
								'video': function(value) {
									if (value) {
										// 触发input框选择视频文件                    
										document.querySelector('.avatar-uploader-editor-video input').click()
									} else {
										this.quill.format('video', false);
									}
								}
							}
						}
					}
				},
				serverUrl: 'erp/v1/common/upload',
				freestyle_stock: 1,
				freestyle_cost: 1,
				// 微信群组
				wxForm: {
					id: '',
					group_ids: [],
					type: 0
				},
				wxList: [],
				wxUserList: [],
				wxGroupList: [],
				wxDialogVisible: false
			}
		},
		mounted() {
			this.getList()
			this.getClassifyList()
			this.getSupplierInfo()

		},
		methods: {
			// 勾选变化
			handleSelectionChange(selection) {
				this.selectionList = JSON.parse(JSON.stringify(selection));

			},
			syncStock(item) {

				let ids = ""
				if (item.id != undefined) {
					ids = item.id
				} else {
					this.selectionList.forEach(row => {
						ids = ids + row.id + ","
					})
				}
				this.loading = true
				this.$http.post(`/erp/v1/goods/sync_stock`, {
					goods_id: ids
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.loading = false
						this.$message.success(msg);
					} else {
						this.$message.error(msg);
					}
				});
			},
			handleExport() {
				this.loading = true
				this.$http.post(`/erp/v1/goods/lst`, {
					export: 1,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.loading = false
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 供应商信息
			getSupplierInfo() {
				this.$http.post(`/erp/v1/home/get_info`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.freestyle_cost = data.freestyle_cost
						this.freestyle_stock = data.freestyle_stock
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 富文本图片上传成功
			uploadSuccess(res, file) {
				let quill = this.$refs.myQuillEditor.quill
				// 如果上传成功        
				if (res.code == 200) {
					// 获取光标所在位置          
					let length = quill.getSelection().index;
					// 插入图片res.url为服务器返回的图片地址
					quill.insertEmbed(length, 'image', res.data.path)
					// 调整光标到最后         
					quill.setSelection(length + 1)
				} else {
					this.$message.error('图片插入失败')
				}
			},
			//上传视频
			uploadSuccessVideo(res, file) {
				let quill = this.$refs.myQuillEditor.quill
				// 如果上传成功        
				if (res.code == 200) {
					// 获取光标所在位置          
					let length = quill.getSelection().index;
					// 插入图片res.url为服务器返回的地址  
					quill.insertEmbed(length, 'video', res.data.path)

					// 调整光标到最后            
					quill.setSelection(length + 1)
				} else {
					this.$message.error('视频插入失败')
				}
			},
			// 富文本图片/视频/音频上传失败   
			uploadError() {
				this.$message.error('插入失败')
			},
			getList() {
				this.$http.post(`/erp/v1/goods/stock_lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.tableData.forEach(item => {
							item.price = Number(item.price)
							item.min_price = Number(item.min_price)
							item.guide_price = Number(item.guide_price)
							item.actual_price = Number(item.actual_price)
							item.cost = Number(item.cost)
							item.stock = Number(item.stock)
							item.warning_stock = Number(item.warning_stock)
						});
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 分类
			getClassifyList() {
				this.$http.post(`/erp/v1/goods_category/lst`, {
					page: this.page,
					pageNum: this.pageNum,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.classifyList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(item) {
				this.form = {
					...item
				}
				this.form.is_top > 0 ? this.form.is_top = 1 : this.form.is_top = 0
				this.form.limit_min <= 1 ? this.isLimitMin = 0 : this.isLimitMin = 1
				this.form.quota <= 0 ? this.isQuota = 0 : this.isQuota = 1
				this.$forceUpdate()
			},
			saveGoods() {
				console.log(this.form, "form")
				if (Number(this.form.min_price) > Number(this.form.price)) {
					this.$message.error('最低折扣价不能高于商品预订价');
					return
				}
				if (this.isQuota == 0) {
					this.form.quota = 0
				}
				if (this.isLimitMin == 0) {
					this.form.limit_min = 1
				}
				if (!this.form.goods_name) {
					this.$message.error('请输入商品名称');
					return
				}
				if (!this.form.goods_intro) {
					this.$message.error('请输入商品卖点');
					return
				}
				if (!this.form.price) {
					this.$message.error('请输入商品预订价');
					return
				}
				// if (!this.form.guide_price) {
				// 	this.$message.error('请输入零售指导价');
				// 	return
				// }
				// if (!this.form.stock) {
				// 	this.$message.error('请输入库存');
				// 	return
				// }
				if (!this.form.goods_pic) {
					this.$message.error('请上传商品图');
					return
				}
				if (this.form.arrived_type == 3) {
					if (!this.form.arrived_date) {
						this.$message.error('请上传到货日期');
						return
					}
				} else {
					this.form.arrived_date = ''
				}
				let url = this.dialogType == 1 ? '/erp/v1/goods/add' : '/erp/v1/goods/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				if (Number(this.form.price) * 0.9 > Number(this.form.min_price)) {
					this.$confirm('最低折扣价已低于预订价的90%, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.saveGoods()
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				} else {
					this.saveGoods()
				}
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/goods/delete`, {
						goods_ids: item.id.toString()
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 下架
			xiajiaFn(item) {
				this.$confirm('此操作将下架该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/goods/field`, {
						id: item.id,
						field: 'status',
						value: 2,
					}).then((res) => {
						this.loading = false
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 微信群组
			getWxAll() {
				this.wxUserList = []
				this.wxGroupList = []
				this.$http.post(`/erp/v1/wx_groups/all`).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.wxList = res.data.data
						this.wxList.forEach(item => {
							if (item.type == 1) {
								this.wxUserList.push(item)
							} else {
								this.wxGroupList.push(item)
							}
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 推送微信社群
			wxFn() {
				this.wxForm.group_ids = this.wxForm.group_ids.toString()
				this.$http.post(`/erp/v1/goods/send_wx`, {
					...this.wxForm
				}).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.wxDialogVisible = false
					} else {
						this.$message.error(msg);
						this.wxForm.group_ids = []
					}
				});
			},
			// 上传
			handleAvatarSuccess(res, file) {
				this.form.goods_pic = res.data.path
				this.$forceUpdate();
			},
			// 输入框修改值
			inputBlur(e, id, val, name) {
				this.$confirm('确定修改该条数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$http.post(`/erp/v1/goods/field`, {
						id: id,
						field: name,
						value: val,
					}).then((res) => {
						this.loading = false
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.getList()
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});

			},
			// 选择表格数据
			selectionChange(e) {
				this.selectionList = e
			},
		},
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}
</style>